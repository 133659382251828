// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    companyId: '625571f3f5cdfd1b1714f688',
    name: 'Bodegas Romero de Ávila Salcedo',
    forceSingleBrand: true,
  },
  {
    companyId: '5c473180aef2352c4fa5e5d2',
    name: 'Avelino Vegas',
    forceSingleBrand: true,
  },
  {
    companyId: '5e1f685ef5daf879e5403909',
    name: 'Bodegas Pinord',
    forceSingleBrand: true,
  },
  {
    companyId: '62557214012eb546d408d40e',
    name: 'Bodegas Salzillo',
    forceSingleBrand: true,
  },
  {
    companyId: '5e1f6823f5daf879e5403799',
    name: ' Puente de Rus',
    forceSingleBrand: true,
  },
  {
    companyId: '625572b42b500f109e2eb98a',
    name: 'Finca Elez',
    forceSingleBrand: true,
  },
  {
    companyId: '625572d3ccade5109d6c5ecb',
    name: 'Santa Catalina',
    forceSingleBrand: true,
  },
  {
    companyId: '62556710f5cdfd1b1714f2b2',
    name: 'Bodegas Montalvo Wilmot',
    forceSingleBrand: true,
  },
  {
    companyId: '62557280f5cdfd1b1714f6af',
    name: 'Just B Galicia Wines',
    forceSingleBrand: true,
  },
];
