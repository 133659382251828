/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { gql } from '@apollo/client';
import {
  Box,
  Col,
  Container,
  SemiTitle,
  Text,
} from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import ContentRow from '@bottlebooks/gatsby-theme-event/src/components/ContentRow';
import NoteableBanner from '@bottlebooks/gatsby-theme-event/src/components/Product/NoteableBanner';
import ProductDetailsSection from '@bottlebooks/gatsby-theme-event/src/components/ProductPage/ProductDetailsSection';
import ExhibitorContacts from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorPage/ExhibitorContacts';

/**
 * A component to contain all the product details sections.
 * It can be used to prepend or append some content in shadowed components.
 * @param {object} props
 * @param {object} props.exhibitor The exhibitor.
 * @param {object} props.product The product.
 * @param {object} props.eventId The eventId.
 * @returns
 */
export default function ProductSections({ exhibitor, product, eventId }) {
  return (
    <React.Fragment>
      <Container>
        <ContentRow>
          <Col flex>
            <Stockists product={product} />
          </Col>
        </ContentRow>
      </Container>
      <Container>
        <ContentRow>
          <Col flex>
            <ExhibitorContacts
              exhibitor={exhibitor}
              eventId={eventId}
              sx={{ marginBottom: 3 }}
            />
            <ProductDetailsSection product={product} />
          </Col>
        </ContentRow>
      </Container>
      <NoteableBanner product={product} />
    </React.Fragment>
  );
}

function Stockists({ product }) {
  const availableInUk = !!product.currentlyAvailableInUkRetail?.value;
  if (!availableInUk) return null;
  const hasStockistDetails = !!product.listOfStockists?.text;
  return (
    <Box
      sx={{
        backgroundColor: '#fed40b',
        padding: 3,
      }}
    >
      <SemiTitle
        sx={{
          marginTop: 1,
          color: '#333',
          padding: 0,
        }}
      >
        Available in UK Retail
      </SemiTitle>
      {availableInUk && hasStockistDetails && (
        <Text variant="large" sx={{ color: '#333' }}>
          {product.listOfStockists.text}
        </Text>
      )}
      {availableInUk && !hasStockistDetails && (
        <Text variant="large" sx={{ color: '#333' }}>
          Contact exhibitor for stockists
        </Text>
      )}
    </Box>
  );
}

export const fragment = graphql`
  fragment custom_ProductSections on Bottlebooks_RegisteredProduct {
    listOfStockists: customFieldValue(key: "listOfStockists") {
      ...FieldValue
    }
    currentlyAvailableInUkRetail: customFieldValue(
      key: "currentlyAvailableInUkRetail"
    ) {
      ...FieldValue
    }
  }
  fragment ProductSections_RegisteredProduct on Bottlebooks_RegisteredProduct {
    ...NoteableBanner
  }
  fragment bb_ProductSections on Bottlebooks_Product {
    ...bb_ProductDetailsSection
  }

  fragment ProductSections on Product {
    ...ProductDetailsSection
  }
`;

ProductSections.fragment = gql`
  fragment custom_ProductSections on Bottlebooks_RegisteredProduct {
    listOfStockists: customFieldValue(key: "listOfStockists") {
      ...FieldValue
    }
    currentlyAvailableInUkRetail: customFieldValue(
      key: "currentlyAvailableInUkRetail"
    ) {
      ...FieldValue
    }
  }
  fragment ProductSections on RegisteredProduct {
    ...ProductDetailsSection_RegisteredProduct
  }
  ${ProductDetailsSection.fragment}
`;
