import type { FilterDefinitions } from '@bottlebooks/gatsby-theme-event/src/components/Filters/useIndex';
import { defineMessage } from '@lingui/macro';
import { graphql as gatsbyGraphQL } from 'gatsby';

// TODO generate this automatically from the fragment.
interface Product {
  vintage?: string;
  grapeVarieties?: ({ varietyName?: string | null } | null)[] | null;
  productType?: string | null;
  wineType?: string | null;
  classification?: string | null;
  characteristics?: (string | null)[] | null;
  specialClassification?: string | null;
  designation?: string | null;
  country?: string | null;
  producer?: { sortName?: string | null; name?: string | null } | null;
  pricing?: {
    priceRange?: string | null;
    price?: number | null;
    currency?: string | null;
  } | null;
  regionHierarchy?: (string | null)[] | null;
  highlights?: { values?: (string | null)[] | null } | null;
  customerSegments?: { values?: (string | null)[] | null } | null;
  thisIsANewProductForTheUkIn2021: { value: boolean | null } | null;
  currentlyAvailableInUkRetail: { value: boolean | null } | null;
}

export default function getProductFilters(): FilterDefinitions<Product> {
  return {
    aggregations: {
      customerSegments: {
        title: defineMessage({ message: 'Kundensegmente' }),
        conjunction: false,
        get: (product: Product) => {
          const customerSegments =
            product.customerSegments?.values?.map((segment) => segment || '') ||
            [];

          return [...customerSegments].filter(Boolean);
        },
        translations: {
          'All customer segments': defineMessage({
            message: 'alle Kundensegmente',
          }),
          'Primarily to on-trade': defineMessage({
            message: 'vorwiegend Gastronomie',
          }),
          'Primarily to small and independent off-trade': defineMessage({
            message: 'vorwiegend unabhängige Einzelhändler',
          }),
          'Primarily to online retail / mail order': defineMessage({
            message: 'vorwiegend Online-Handel/Versandhandel',
          }),
        },
        size: 20,
      },
      highlights: {
        title: defineMessage({ message: 'Highlights' }),
        conjunction: false,
        get: (product: Product) => {
          const highlights =
            product.highlights?.values?.map((highlight) => highlight || '') ||
            [];
          const currentlyAvailableInUkRetail = product
            .currentlyAvailableInUkRetail?.value
            ? 'Available in UK Retail'
            : undefined;
          const thisIsANewProductForTheUkIn2021 = product
            .thisIsANewProductForTheUkIn2021?.value
            ? 'New for 2022'
            : undefined;
          return [
            // Exclude 'None'
            ...highlights.filter((highlight) => highlight !== 'None'),
            currentlyAvailableInUkRetail,
            thisIsANewProductForTheUkIn2021,
          ].filter(Boolean);
        },
        size: 20,
      },
      productType: {
        title: defineMessage({ message: 'Product types' }),
        conjunction: false,
        get: (product: Product) =>
          product.wineType ||
          // product.spiritType ||
          // product.beerType ||
          product.productType,
        size: 20,
        translations: {
          WINE: defineMessage({ message: 'Other wine' }),
          SPIRIT: defineMessage({ message: 'Spirit' }),
          Stillwein: defineMessage({ message: 'Wine' }),
          BEER: defineMessage({ message: 'Beer and more' }),
        },
      },
      // country: {
      //   title: defineMessage({ message: 'Countries' }),
      //   conjunction: false,
      //   multiSelect: false,
      //   size: 100,
      //   get: (product) => product.country || undefined, // Excludes nulls
      // },
      regionHierarchy: {
        title: defineMessage({ message: 'Winegrowing regions' }),
        conjunction: false,
        // multiSelect: false,
        size: 100,
        get: (product) => product.regionHierarchy?.map((e) => e || '') || [], // Excludes nulls
      },
      primaryGrapeVariety: {
        title: defineMessage({ message: 'Primary grape varieties' }),
        conjunction: false,
        size: 300,
        get: (product) => {
          const varieties = product?.grapeVarieties
            ?.map(({ varietyName }) => varietyName)
            .filter(Boolean);
          if (!varieties?.length) return [];
          return varieties[0];
        },
      },

      allGrapeVarieties: {
        title: defineMessage({ message: 'All grape varieties' }),
        conjunction: true,
        multiSelect: true,
        size: 300,
        get: (product) => {
          const varieties = product?.grapeVarieties
            ?.map(({ varietyName }) => varietyName)
            .filter(Boolean);
          if (!varieties?.length) return [];
          if (varieties.length === 1) return ['Single variety', ...varieties];
          if (varieties.length > 1) return ['Blend', ...varieties];
          return varieties;
        },
        translations: {
          Blend: defineMessage({ message: 'Blend' }),
          'Single variety': defineMessage({ message: 'Single variety' }),
        },
      },
      vintage: {
        title: defineMessage({ message: 'Vintages' }),
        conjunction: false,
        size: 50,
        sort: 'term',
        order: 'desc',
        get: (product: Product) => {
          return product.vintage || undefined;
        }, // Excludes nulls
        translations: {
          NV: defineMessage({ message: 'NV' }),
        },
      },
      priceRanges: {
        title: defineMessage({ message: 'Price ranges' }),
        conjunction: false,
        sort: 'term',
        // This comes from ProductsTemplate.afterQuery.
        get: (product: Product) => product.pricing?.priceRange || undefined, // Excludes nulls
      },
      productionMethods: {
        title: defineMessage({ message: 'Production methods' }),
        conjunction: false,
        size: 20,
        get: (product) =>
          product?.characteristics
            ?.filter((value) =>
              [
                'VEGAN',
                'ORGANIC',
                'VEGETARIAN',
                'BIODYNAMIC',
                'KOSHER',
                'SUSTAINABLE',
              ].includes(value || '')
            )
            .map((e) => e || '') || [],
        translations: {
          VEGAN: defineMessage({ message: 'vegan' }),
          ORGANIC: defineMessage({ message: 'organic' }),
          VEGETARIAN: defineMessage({ message: 'vegetarian' }),
          BIODYNAMIC: defineMessage({ message: 'biodynamic' }),
          KOSHER: defineMessage({ message: 'kosher' }),
          SUSTAINABLE: defineMessage({ message: 'sustainable' }),
        },
      },

      classification: {
        title: defineMessage({ message: 'Classifications' }),
        conjunction: false,
        size: 50,
        get: (product) => {
          if (!product?.classification) return undefined; // Excludes nulls
          if (product.classification === '----') return undefined;
          if (product.classification === 'No classification') return undefined;
          return product.classification;
        },
      },
      specialClassification: {
        title: defineMessage({ message: 'Special classifications' }),
        conjunction: false,
        size: 50,
        get: (product) => {
          if (!product?.specialClassification) return undefined; // Excludes nulls
          if (product.specialClassification === '----') return undefined;
          return product.specialClassification;
        },
      },
      designation: {
        title: defineMessage({ message: 'Designations' }),
        conjunction: false,
        size: 50,
        get: (product) => product.designation || undefined, // Excludes nulls
      },
      closureTypes: {
        title: defineMessage({ message: 'Closure types' }),
        conjunction: false,
        size: 50,
        get: (product) => product.bottleVariants?.[0]?.closureType || undefined, // Excludes nulls
      },
    },

    sortings: {
      // byProducerName: {
      //   title: defineMessage({ message: 'Producer name' }),
      //   field: [
      //     (product) => product?.producer?.sortName || product?.producer?.name,
      //   ],
      //   order: 'asc',
      //   group: 'producer.producerId',
      //   groupHeader: 'producer',
      // },
      byProductName: {
        title: defineMessage({ message: 'Product name' }),
        field: ['shortName', 'vintage'],
        order: ['asc', 'desc'],
      },
      // byExhibitorName: {
      //   title: defineMessage({ message: 'Exhibitor name' }),
      //   field: [
      //     'exhibitor.sortName',
      //     'exhibitor.name',
      //     'producer.sortName',
      //     'producer.name',
      //   ],
      //   order: 'asc',
      //   group: 'exhibitorId',
      // },
      // byStandNumber: {
      //   title: defineMessage({ message: 'Table number' }),
      //   field: [
      //     sortBy.standName,
      //     'exhibitor.sortName',
      //     'exhibitor.name',
      //     'producer.sortName',
      //     'producer.name',
      //   ],
      //   order: 'asc',
      //   group: 'registration.registrationId',
      // },
    },
  };
}

// Sort helper functions
const sortBy = {
  standName: (product) =>
    product?.stand?.name
      .split(/[\s,-]+/)
      .map((segment) =>
        segment === 'Tisch'
          ? undefined
          : Number(segment)
          ? segment.padStart(3, '0')
          : segment
      )
      .filter(Boolean)
      .join(' ') || '999',
};

export const fragment = gatsbyGraphQL/* GraphQL */ `
  fragment wfs_productFilters on Bottlebooks_RegisteredProduct {
    registration {
      registrationId
      profile {
        sortName
        name
      }
    }
    customerSegments: customFieldValue(key: "customerSegment") {
      ...FieldValue
    }
    highlights: customFieldValue(key: "highlights") {
      ...FieldValue
    }
    currentlyAvailableInUkRetail: customFieldValue(
      key: "currentlyAvailableInUkRetail"
    ) {
      ...FieldValue
    }
    thisIsANewProductForTheUkIn2021: customFieldValue(
      key: "thisIsANewProductForTheUkIn2021"
    ) {
      ...FieldValue
    }
    product {
      name
      producer {
        producerId
        sortName
        name
        # For rendering the producer header
        countryName: country(format: LOCALIZED)
        countryCode: country(format: RAW)
        city
      }
      productType
      ... on Bottlebooks_Wine {
        wineType
        characteristics
        classification
        vintage(removeNonVintage: true)
        denomination
        grapeVarieties {
          varietyName
        }
      }
      # ... on Bottlebooks_Beer {
      #   beerType
      # }
      ... on Bottlebooks_Spirit {
        spiritType
      }
    }
    registration {
      stand {
        name
      }
    }
  }
`;
