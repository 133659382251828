import base from '@bottlebooks/gatsby-theme-event/src/locales/de';
import de from '../../../locales/de';
const merged = {
  messages: {
    ...base.messages,
    ...de.messages,
  },
};

export default merged;
