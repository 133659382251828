/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, Container, Link, Text } from '@bottlebooks/gatsby-theme-base';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
import { LinkProvider } from '@bottlebooks/gatsby-theme-event/src/useLink';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import { Trans } from '@lingui/macro';
import ExploreNewReleases from '../../components/ExploreWines/ExploreNewReleases';
import ExploreWinesSwitcher from '../../components/ExploreWines/ExploreWinesSwitcher';

export default function NewReleasesPage({ pageContext, data }) {
  const { bottlebooks } = data;
  const { importers, exporters } = bottlebooks;
  return (
    <LinkProvider value={pageContext.paths}>
      <Layout {...pageContext}>
        <Box sx={{ backgroundColor: 'light', paddingTop: [2, null, 4] }}>
          <Container>
            <ExploreWinesSwitcher>
              <Link to="/products">
                <Text>
                  <Trans>
                    Or search by vegan, organic, on-trade and 10 other filters….
                  </Trans>
                </Text>
              </Link>
            </ExploreWinesSwitcher>
            <ExploreNewReleases
              wines={exporters.wines.nodes}
              sx={{ paddingY: 3, backgroundColor: 'white' }}
            />
          </Container>
        </Box>
      </Layout>
    </LinkProvider>
  );
}

export const query = graphql`
  query NewReleasesPage($locale: Bottlebooks_ContentLocale!) {
    bottlebooks {
      exporters: event(eventId: "62875a78e65b2f107e2710d4", locale: $locale) {
        ...NewReleasesPage_Fragment
      }
    }
  }
  fragment NewReleasesPage_Fragment on Bottlebooks_Event {
    wines: registeredProducts {
      nodes {
        ...ExploreNewReleases
      }
    }
  }
`;
