/** @jsxRuntime classic */
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Link, Text } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import { Plural } from '@lingui/macro';

export default function Variety({ variety, mode = 'small', ...rest }) {
  const titleVariant = mode === 'small' ? 'gridLabel' : 'largeGridLabel';

  // Resolve wine color by checking if there is an excecption
  const exception = exceptions.find(
    (exception) => exception.varietyName === variety.name
  );
  const resolvedWineColor = exception?.wineColor || variety.wineColor;
  return (
    <Link
      fallback="div"
      to={`/products#primaryGrapeVariety=${variety.name}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center',
        transition: 'none',
        border: 2,
        padding: 2,
        color: `variety.${resolvedWineColor}.text`,
        borderColor: `variety.${resolvedWineColor}.border`,
        backgroundColor: `variety.${resolvedWineColor}.border`,
        ':hover': {
          color: resolvedWineColor === 'white' ? 'black' : 'white',
          borderColor: 'black',
        },
      }}
      {...rest}
    >
      <Text variant={titleVariant} sx={{ overflowWrap: 'anywhere' }}>
        {variety.name}
      </Text>
      <Text variant="smallest">
        <Plural value={variety.productCount} one="# wine" other="# wines" />
      </Text>
    </Link>
  );
}

// Sometimes a producer indicates a wine is red or rose, which is not supported by the theme.
// We can handle that with an exceptions list.
const exceptions = [
  { varietyName: 'Petit Manseng', wineColor: 'white' },
  { varietyName: 'Garnatxa Blanca', wineColor: 'white' },
];

export const fragment = graphql`
  fragment Variety on Bottlebooks_RegisteredProduct {
    product {
      ... on Bottlebooks_Wine {
        wineColor(format: RAW)
      }
    }
  }
`;

Variety.fragment = gql`
  fragment Variety on RegisteredProduct {
    name
    ... on Wine {
      grapeVarieties {
        varietyName
      }
    }
  }
`;
