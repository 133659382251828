import { graphql as gatsbyGraphQL, useStaticQuery } from 'gatsby';
import React from 'react';
import useLocale from '@bottlebooks/gatsby-theme-event/src/useLocale';

export default function useEventData() {
  /** @type {EventData} */
  const eventData = useStaticQuery(QUERY);
  const locale = useLocale();

  return React.useMemo(() => {
    const {
      exhibitors: { nodes: allExhibitors },
      products: { nodes: allProducts },
      collection,
    } = eventData;

    const exhibitors = allExhibitors.filter(
      (exhibitor) => exhibitor.locale === locale
    );
    const products = allProducts.filter((product) => product.locale === locale);
    return {
      exhibitors,
      products: products?.map((product) => ({
        ...product,
        denomination: product.subregion || product.region,
        exhibitor: exhibitors.find(
          (exhibitor) => exhibitor.exhibitorId === product.exhibitorId
        ),
      })),
      collection,
    };
  }, [eventData, locale]);
}

const QUERY = gatsbyGraphQL/* GraphQL */ `
  fragment SearchResult_Product on Product {
    shortName
    ...ProductImageLarge_list
    exhibitor {
      name
    }
    producer {
      name
    }
    ...useLink_Product
  }

  fragment SearchResult_Brand on AbstractBrand {
    name
    countryName: country(full: true)
    region
    city
    ...BrandLogoLarge_list
    ...useLink_exhibitor_AbstractBrand
  }

  query wfs_SiteSearch {
    collection {
      collectionId
      name
    }
    exhibitors: allExhibitor {
      nodes {
        brandId
        locale
        # ...ExhibitorListRow_mainImage
        ...SearchResult_Brand
        ...Search_Exhibitor
      }
    }
    products: allProduct {
      nodes {
        productId
        locale
        countryName: country(full: true)
        countryCode: country(full: false)
        region
        subregion
        ...SearchResult_Product
        ...Search_Product

        # needed for multi-event list
        alcohol
        totalAcidity
        residualSugar
      }
    }
  }
`;

// TODO Filter on locale
export function useExhibitors() {
  const { exhibitors } = useEventData();
  return {
    all: exhibitors,
    /** @param {string} exhibitorId */
    findById(exhibitorId) {
      return exhibitors.find(({ brandId }) => brandId === exhibitorId);
    },
  };
}

// TODO Filter on locale
/** @param {string | undefined} exhibitorId */
export function useExhibitor(exhibitorId) {
  const exhibitors = useExhibitors();
  if (!exhibitorId) return undefined;
  return exhibitors.findById(exhibitorId);
}

// TODO Filter on locale
export function useProducts() {
  const { products } = useEventData();
  return {
    all: products,
    filter: (
      /** @type {(product: ReturnType<useEventData>["products"][number], index: number, allProducts: ReturnType<useEventData>["products"]) => boolean}*/ predicate
    ) => products.filter(predicate),

    findByExhibitorId(/** @type {string} */ exhibitorId) {
      return products.filter((product) => product.exhibitorId === exhibitorId);
    },

    findById(/** @type {string} */ productId) {
      return products.find((product) => product.productId === productId);
    },
  };
}

/** @param {string | undefined} productId */
export function useProduct(productId) {
  const products = useProducts();
  if (!productId) return undefined;
  return products.findById(productId);
}

export function useCollection() {
  const { collection } = useEventData();
  return collection;
}

/**
 * @typedef {{
 *  collection: {
 *    collectionId: string;
 *    name: string;
 *  };
 *  exhibitors: {
 *    nodes: Array<{
 *      brandId: string;
 *      locale: string;
 *      exhibitorId: string;
 *    }
 *    & SearchResult_Brand
 *    & import('../ExhibitorsPage/useExhibitorSearch').Search_Exhibitor
 *    >
 *  };
 *  products: {
 *    nodes: Array<{
 *      productId: string;
 *      locale: string;
 *      countryName?: string?;
 *      countryCode?: string?;
 *      region?: string?;
 *      subregion?: string?;
 *    }
 *    & SearchResult_Product
 *    & import('../ProductsPage/useProductSearch').Search_Product
 *    >
 *  };
 * }} EventData
 */

/**
 * @typedef {{
 *  name: string;
 *  countryName: string | null;
 *  region: string | null;
 *  city: string | null;
 *  logo: {
 *    fixed: {
 *      src: string;
 *      height: number;
 *      width: number;
 *    } | null;
 *  } | null;
 * }} SearchResult_Brand
 */

/**
 * @typedef {{
 *  shortName: string;
 *  exhibitor: { name: string };
 *  producer: { name: string };
 *  productId: string;
 *  exhibitorId: string;
 * }
 * & import('../Product/ProductImage').ProductImage_list} SearchResult_Product
 */
