/** @jsx jsx */
import { gql } from '@apollo/client';
import { Badge, Box } from '@bottlebooks/gatsby-design-system';
import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import getOnAccentColor from '@bottlebooks/gatsby-theme-event/src/helpers/getOnAccentColor';

const variants = {
  default: {
    paddingX: 3,
    paddingY: 1,
  },
  small: {
    paddingX: 2,
    paddingY: 0,
  },
};

export default function SeekingDistribution({
  brand,
  variant = 'default',
  ...rest
}) {
  // Don't show for Germany
  return null;
  // if (!brand) return null;
  // if (!brand.isSeekingDistribution) return null;
  // return (
  //   <Box {...rest}>
  //     <Badge
  //       sx={(theme) => {
  //         return {
  //           backgroundColor: 'accent',
  //           // Avoid hover effect
  //           color: getOnAccentColor(theme.colors.accent),
  //           ...variants[variant],
  //         };
  //       }}
  //     >
  //       <Trans>Seeking distribution</Trans>
  //     </Badge>
  //   </Box>
  // );
}

export const fragment = graphql`
  fragment bb_RegisteredBrand_SeekingDistribution on Bottlebooks_RegisteredBrand {
    isSeekingDistribution
  }
`;

export const SeekingDistributionFragment = gql`
  fragment RegisteredBrand_SeekingDistribution on RegisteredBrand {
    isSeekingDistribution
  }
`;
