/** @jsx jsx */
import ExhibitorsPage from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/ExhibitorsPage';
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';
import {
  extractPartnerIdsForCharacteristics,
  getCharacteristicsForId,
} from '@bottlebooks/gatsby-theme-event-pages/src/helpers/brandCertifications';

export default function ExhibitorsTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { event, exhibitors, site } = afterQuery(data);
  // This is how we should do this in the future - take the settings from event
  // But for the moment, we are loading them from environment variables using useSiteConfig
  // const { filterSeekingDistribution, filterHasDistribution } = event || {};
  return (
    <ExhibitorsPage
      {...pageContext}
      event={event}
      exhibitors={exhibitors}
      siteTitle={site?.siteMetadata?.title}
      location={location}
      navigate={navigate}
    />
  );
}

export const pageQuery = graphql`
  fragment wfs_ExhibitorsTemplate_Bottlebooks_Event on Bottlebooks_Event {
    collectionId: eventId
    exhibitors {
      nodes {
        exhibitorId
        ...bb_ExhibitorsPage
      }
    }
    registrations {
      nodes {
        ...exhibitorFilters
      }
    }
    registeredBrands {
      nodes {
        partnerId
        ...bb_ExhibitorsPage_RegisteredBrand
        ...exhibitorFilters_RegisteredBrand
      }
    }
    # This would bring down the api server, so it's disabled by default.
    # registeredProducts {
    #   ...bb_BrandFilters_RegisteredProductConnection
    # }
  }

  query wfs_ExhibitorsTemplate(
    $locale: Bottlebooks_ContentLocale
    $filter: ExhibitorFilterInput
    $sort: ExhibitorSortInput
    $localeString: String
  ) {
    bottlebooks {
      importers: event(eventId: "6245ad03043b5d0f1a43647e", locale: $locale) {
        ...wfs_ExhibitorsTemplate_Bottlebooks_Event
      }
      exporters: event(eventId: "6245ab56e459d70efdc320c8", locale: $locale) {
        ...wfs_ExhibitorsTemplate_Bottlebooks_Event
      }
    }
    event(locale: { eq: $localeString }) {
      ...ExhibitorsTemplate_Event
    }
    firstExhibitors: allExhibitor(filter: $filter, sort: $sort, limit: 10) {
      nodes {
        ...ExhibitorsPage_first
      }
    }
    exhibitors: allExhibitor(filter: $filter, sort: $sort) {
      nodes {
        exhibitorId
        ...ExhibitorsPage
        ...exhibitorMultiEventFilter
      }
    }
    ...ExhibitorsTemplate_Query
  }

  fragment ExhibitorsTemplate_Event on Event {
    # This is how we should do this in the future - take the settings from event
    # But for the moment, we are loading them from environment variables using useSiteConfig
    # filterSeekingDistribution
    # filterHasDistribution
    ...ExhibitorsPage_Event
  }

  fragment ExhibitorsTemplate_Query on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

function afterQuery({ bottlebooks, event, firstExhibitors, exhibitors, site }) {
  const bb_event = bottlebooks?.event;
  const { importers, exporters } = bottlebooks;
  const bb_exhibitors_importers = importers?.exhibitors?.nodes;
  const bb_exhibitors_exporters = exporters?.exhibitors?.nodes;
  const all_bb_exhibitors = [
    ...bb_exhibitors_importers,
    ...bb_exhibitors_exporters,
  ];
  const bb_registeredBrands_importers = importers?.registeredBrands?.nodes;
  const bb_registeredBrands_exporters = exporters?.registeredBrands?.nodes;
  const all_bb_registeredBrands = [
    ...bb_registeredBrands_importers,
    ...bb_registeredBrands_exporters,
  ];
  // Enrich the first exhibitors with better images and base64 previews.
  const combinedExhibitors = exhibitors.nodes?.map((exhibitor, index) => {
    const refinedExhibitor = firstExhibitors?.nodes[index];
    const bb_exhibitor = all_bb_exhibitors?.find(
      ({ exhibitorId }) => exhibitorId === exhibitor.exhibitorId
    );
    const bb_registeredBrand = all_bb_registeredBrands?.find(
      ({ partnerId }) => partnerId === exhibitor.exhibitorId
    );
    return deepMerge(
      {},
      exhibitor,
      bb_exhibitor,
      bb_registeredBrand,
      refinedExhibitor,
      {
        characteristics: getCharacteristicsForId({
          extractIdsFn: extractPartnerIdsForCharacteristics,
          id: exhibitor.exhibitorId,
          event: bb_event,
        }),
      }
    );
  });

  return {
    event,
    site,
    exhibitors: combinedExhibitors,
  };
}
