/** @jsxRuntime classic */
/** @jsx jsx */
import { Grid } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import BookAMeetingButton from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandContacts/BookAMeetingButton';
import Contact from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandContacts/Contact';

/**
 * A component to contain all the exhibitor details sections.
 * It can be used to prepend or append some content in shadowed components.
 * @param {object} props
 * @param {object} props.exhibitor The exhibitor.
 * @param {string} props.eventId The event id.
 * @returns
 */
export default function ExhibitorContacts({ exhibitor, eventId, ...rest }) {
  if (!hasContent(exhibitor)) return null;

  const distributionContacts = (exhibitor.brands || [])
    ?.flatMap((b) => b.distributionContacts || [])
    .filter(Boolean);
  const exhibitorContacts = exhibitor.exhibitorContacts || [];
  const allContacts = [...exhibitorContacts, ...distributionContacts].filter(
    (contact) => !!contact.fullName
  );
  // Contacts may be entered multiple times.
  // We need to create a unique list and aggregate the contact types
  const uniqueContactsMap = allContacts.reduce((acc, contact) => {
    const existingContact = acc[contact.contactId];
    if (!existingContact) {
      acc[contact.contactId] = contact;
      return acc;
    }
    acc[contact.contactId] = {
      ...existingContact,
      contactTypes: [
        ...(existingContact?.contactTypes || []),
        ...(contact?.contactTypes || []),
      ].filter(Boolean),
    };
    return acc;
  }, {});
  const uniqueContacts = Object.values(uniqueContactsMap);
  return (
    <Grid sx={{ marginTop: 3 }} width={300} {...rest}>
      {uniqueContacts?.map((contact) => (
        <Contact key={contact.contactId} contact={contact}>
          <BookAMeetingButton
            collectionId={eventId}
            contact={contact}
            exhibitor={exhibitor}
            contactButtonVisibility="ENABLED"
            contactButtonAuthenticationMode="OPTIONAL"
            sx={{ marginTop: 2 }}
          />
        </Contact>
      ))}
    </Grid>
  );
}

function hasContent(exhibitor) {
  if (!exhibitor) return false;
  if (exhibitor.exhibitorContacts?.length) return true;
  if (exhibitor.brands?.[0]?.distributionContacts?.length) return true;
  return false;
}

export const fragment = graphql`
  fragment bb_Registration_Contacts on Bottlebooks_Registration {
    ... on Bottlebooks_SingleRegistration {
      exhibitorContacts: contacts {
        ...bb_Contact
      }
    }
  }
`;
