/** @jsxRuntime classic */
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Button, DownloadIcon } from '@bottlebooks/gatsby-theme-base';
// import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';

export default function ProductInfoSheetButton({ product, ...rest }) {
  if (!product?.infoSheet?.url) return null;
  // WFS doesn't like the newer design, which is now the default.
  // In order to support their request, we created a configuration in portfolio and use it here.
  const tokens = product.infoSheet.url.split('?');
  const url = `${tokens[0]}/configurations/627af4ba12b67818fc82710f?${tokens[1]}`;
  return (
    <Button
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      variant="outline"
      sx={{ textAlign: 'center' }}
      {...rest}
    >
      <DownloadIcon
        size="small"
        sx={{ marginRight: 2, color: 'currentColor' }}
      />
      Download info sheet
    </Button>
  );
}

export const fragment = graphql`
  fragment bb_ProductInfoSheetButton on Bottlebooks_Product {
    infoSheet {
      url
    }
  }
`;

ProductInfoSheetButton.fragment = gql`
  fragment ProductInfoSheetButton on Product {
    infoSheet {
      url
    }
  }
`;
