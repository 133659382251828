import { defineMessage } from '@lingui/macro';
import { graphql as gatsbyGraphQL } from 'gatsby';
import type { FilterDefinitions } from '@bottlebooks/gatsby-theme-event/src/components/Filters/useIndex';
import { rankings } from 'match-sorter';
// TODO generate this automatically from the fragment.
interface Exhibitor {
  region: string | null;
  exhibitorId: string | null;
  countryName?: string | null;
  isSeekingDistribution: boolean | null;
  profile?: {
    distributionInCountries?: (string | null)[] | null;
    seekingDistributionInCountries?: (string | null)[] | null;
  } | null;
  stand?: { name?: string | null } | null;
  event: { eventId: string | null; name?: string | null };
}

export default function getExhibitorFilters(): FilterDefinitions<Exhibitor> {
  return {
    aggregations: {
      exhibitorType: {
        title: defineMessage({ message: 'Austellertyp' }),
        // multiSelect: false, // TODO Implement
        size: 20,
        get: (exhibitor) => {
          return exhibitor.exhibitorId === '5e1f6596f5daf879e5402b9f'
            ? 'importers'
            : 'producers';
        },
        translations: {
          importers: defineMessage({ message: 'Importeurer' }),
          producers: defineMessage({ message: 'Produzenten' }),
        },
      },
    },

    sortings: {
      byStandName: {
        title: defineMessage({ message: 'Table number' }),
        field: [sortBy.standName, 'profile.sortName', 'name'],
        order: 'asc',
      },
      // byDataCompleteness: {
      //   title: defineMessage({ message: 'By data completeness' }),
      //   field: [
      //     sortBy.standName,
      //     sortBy.dataCompleteness,
      //     'profile.sortName',
      //     'name',
      //   ],
      //   order: 'asc',
      // },
      byExhibitorName: {
        title: defineMessage({ message: 'Exhibitor name' }),
        field: ['profile.sortName', 'name'],
        order: 'asc',
      },
    },
    search: {
      keys: [
        { key: 'name', threshold: rankings.CONTAINS },
        (exhibitor) =>
          `${exhibitor.countryName || ''} ${exhibitor.region || ''} ${
            exhibitor.city || ''
          }`.trim(),
        (exhibitor) => exhibitor?.stand?.name?.replace(/[()]/g, '') || '',
      ],
    },
  };
}

const completeness = {
  ORGANIZER_REVIEWED: 1,
  STEP_COMPLETED: 2,
  STEP_PAYMENT: 2,
  STEP_REVIEW: 3,
};

// Sort helper functions
const sortBy = {
  standName: (exhibitor: Exhibitor) => {
    return exhibitor?.stand?.name
      .split(/[\s,-]+/)
      .map((segment) =>
        segment === 'Tisch'
          ? undefined
          : Number(segment)
          ? segment.padStart(3, '0')
          : segment
      )
      .filter(Boolean);
  },
  dataCompleteness: (exhibitor) =>
    completeness[exhibitor?.registrationStatus] -
    (exhibitor?.registeredProducts?.totalCount > 0 ? 1 : 0),
};

export const fragment = gatsbyGraphQL/* GraphQL */ `
  fragment exhibitorMultiEventFilter on Exhibitor {
    event {
      eventId
      name
    }
  }
  fragment exhibitorFilters on Bottlebooks_SingleRegistration {
    companyId
    stand {
      name
    }
    registrationStatus
    registeredProducts {
      totalCount
    }
    profile {
      name
      sortName
      countryName: country(format: LOCALIZED)
      region
      city
      distributionInCountries
      seekingDistributionInCountries
      # priceRanges
      sortName
    }
  }
  fragment exhibitorFilters_RegisteredBrand on Bottlebooks_RegisteredBrand {
    isSeekingDistribution
  }
`;
