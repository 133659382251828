import base from '@bottlebooks/gatsby-theme-event/src/locales/en';
import en from '../../../locales/en';
const merged = {
  messages: {
    ...base.messages,
    ...en.messages,
  },
};

export default merged;
