/** @jsx jsx */
/** @jsxFrag React.Fragment */
// @ts-check
import { gql as apolloGql } from '@apollo/client';
// eslint-disable-next-line import/no-unresolved
import Card from '@bottlebooks/gatsby-design-system/src/components/Card/Card';
import { Label, Link, SemiTitle, Text } from '@bottlebooks/gatsby-theme-base';
import { useFavorite } from '@bottlebooks/gatsby-theme-event/plugins/gatsby-plugin-favorites/src';
import ToggleFavorite from '@bottlebooks/gatsby-theme-event/plugins/gatsby-plugin-favorites/src/components/ToggleFavorite';
import { graphql as gatsbyGraphQL } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import ListRowBody from '@bottlebooks/gatsby-theme-event/src/components/List/ListRowBody';
import {
  ProductHeaderDetails,
  ProductHeaderFragment,
  ProductStand,
} from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductHeader';
import ProductImage, {
  ProductImageFragment_large,
} from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductImage';
import BaseProductName from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductName';
import ProductCardActionsBar from '@bottlebooks/gatsby-theme-event/src/components/ProductCard/ProductCardActionsBar';
import ProductCardFeatures from '@bottlebooks/gatsby-theme-event/src/components/ProductCard/ProductCardFeatures';
import brandSettings from '../../../../brandSettings';

/** @param {{ product: object; className?: string; style?: React.CSSProperties; elevation?: Parameters<Card>[0]["elevation"]; [x: string]: any; }} props */
export default function ProductCard({
  product,
  className,
  style,
  elevation,
  ...rest
}) {
  const favorite = useFavorite(
    product && { type: 'product', id: product.productId }
  );
  const forceSingleBrand = brandSettings.find(
    (b) => b.companyId === product.exhibitorId
  )?.forceSingleBrand;
  return (
    <Card
      // 1fr for the first row lets the card take up the full height.
      sx={{ gridTemplateRows: '1fr' }}
      elevation={elevation}
      className={className}
      style={style}
    >
      <Link
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          color: 'inherit',
          // Align the product features to the bottom of the main content.
          position: 'relative',
        }}
        {...rest}
      >
        <ProductImage
          product={product}
          size="large"
          sx={{
            maxWidth: '35%',
            height: 'initial',
            margin: 'auto',
            paddingRight: 0,
            '.gatsby-image-wrapper': { paddingTop: '200%', height: 0 },
          }}
        />
        <ListRowBody sx={{ marginTop: 4 }}>
          <ProductStand stand={product?.stand} exhibitor={undefined} to="" />
          <Label color="text">{product?.exhibitor?.name}</Label>
          {product?.exhibitor?.name !== product?.producer?.name &&
            !forceSingleBrand && (
              <Text variant="smallest" sx={{ marginTop: 2 }}>
                {product?.producer.name}
              </Text>
            )}
          <SemiTitle sx={{ paddingTop: 1, paddingBottom: 2 }}>
            <BaseProductName {...product} />
          </SemiTitle>
          <ProductHeaderDetails product={product} />
        </ListRowBody>
        <ProductCardFeatures
          product={product}
          sx={{ position: 'absolute', bottom: 2.5, right: 2.5 }}
        />
      </Link>
      {!favorite.isLoading && (
        <ToggleFavorite
          isToggled={favorite.isSet}
          variant="small"
          sx={{ position: 'absolute', top: 0, right: 0 }}
          onClick={() => favorite.toggle()}
        />
      )}
      <ProductCardActionsBar
        product={product}
        sx={{ borderTop: 1, borderTopColor: 'border' }}
      />
    </Card>
  );
}

// HEADSUP: Product features have to be queried separately by the parent.

export const fragment = gatsbyGraphQL/* GraphQL */ `
  fragment bb_ProductCard on Bottlebooks_Product {
    ...bb_ProductRequestSampleWithSnipcartButton
    ...bb_ProductHeader
  }
  fragment ProductCard on Product {
    ...ProductImageLarge
    ...ProductHeader
  }

  # A fragment for the first page of products. It has more detailed images.
  fragment ProductCard_first on Product {
    ...ProductImageLarge
  }
`;

ProductCard.fragment = apolloGql/* GraphQL */ `
  fragment ProductCard on Product {
    ...ProductImageLarge
    ...ProductHeader
  }
  ${ProductImageFragment_large}
  ${ProductHeaderFragment}
`;
