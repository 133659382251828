/** @jsxRuntime classic */
/** @jsx jsx */
import { Grid, Section } from '@bottlebooks/gatsby-theme-base';
import { Plural } from '@lingui/macro';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import DistributionContacts from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandContacts/DistributionContacts';
import PresentedBrand from '@bottlebooks/gatsby-theme-event/src/components/Brand/PresentedBrand';
import brandSettings from '../../../../brandSettings';
export default function PresentedBrands({ brand }) {
  // This should only happen if there are no products registered.
  if (!hasContent(brand?.brands)) return null;
  if (
    brandSettings.find((b) => b.companyId === brand.exhibitorId)
      ?.forceSingleBrand
  )
    return null;
  const { brands } = brand;
  // We don't need to show the brand if it's the only brand and also the exhibitor.
  // Also, this only displays when there are distribution contacts active for an event.
  if (brands.length === 1 && brands[0].companyId === brands[0].brandId) {
    return (
      <Section title="">
        <DistributionContacts brand={brands[0]} />
      </Section>
    );
  }
  return (
    <Section
      title={
        <Plural
          comment="If there is a single brand only, we want to hide the title. So leave the title empty for a single brand."
          value={brands.length}
          one=""
          other="Our Brands"
        />
      }
    >
      <Grid width={300}>
        {brands?.map((brand) => (
          <PresentedBrand key={brand.brandId} brand={brand} />
        ))}
      </Grid>
    </Section>
  );
}
PresentedBrands.hasContent = hasContent;

function hasContent(brands) {
  if (!brands?.length) return false;
  // We assume the brands always have some valid data (the brand name should be mandatory).
  return true;
}

export const fragment = graphql`
  fragment PresentedBrands on Bottlebooks_RegisteredBrand {
    ...bb_DistributionContacts
    ...bb_RegisteredBrand_PresentedBrand
  }
`;
