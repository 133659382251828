export default function formatProducers(producers) {
  return producers.products?.byRegion?.flatMap(({ fieldValue, byProducer }) =>
    byProducer.map(({ nodes }) => {
      const denominationTokens = fieldValue?.split(' ') || [];
      // The prefix should be a standard length so we can sort as a simple string
      const prefix = denominationTokens[0].padEnd(4, '_').substring(0, 2);
      return {
        sortByDenomination: [
          prefix,
          denominationTokens[1],
          denominationTokens[2],
          denominationTokens[3],
          denominationTokens[4],
        ]
          .filter(Boolean)
          .join('_'),
        denomination: fieldValue,
        product: nodes[0].product,
        ...nodes[0].product?.producer,
      };
    })
  );
}
